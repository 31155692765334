<template>    
    <v-sheet v-if="TonsInDay.length > 0"  class="ma-4 mx-auto" color="info" >
        <v-container class="bg-surface-variant mb-6">											
            <v-row no-gutters justify="center">                
                <v-col v-for="item2 in TonsInDay" :key="item2.Dias" cols="2" >      
                    <v-sheet  class="mx-auto" :color="item2.Color"
                   >
                   <v-sheet :color="item2.Color">
                        <v-row justify="center" >
                            <h4 class="white--text" >{{ item2.FechaIngreso }}</h4>																							
                        </v-row>
                        <v-row justify="center" >
                            <h4 class="white--text" >{{ item2.Dias }} Dias</h4>																							
                        </v-row>
                        <v-row justify="center" >
                            <h5 class="white--text" >{{ item2.TotalTonelada + ' TM' }}</h5>																
                        </v-row>	
                    </v-sheet>
                        
                    </v-sheet>																									
                </v-col>                																								
            </v-row>
        </v-container>
    </v-sheet>
    
</template>

<script>

export default 
{
    
    props: {
		TonsInDay: [],
	},
    data(){
        return{
            date:'',
            month:'',
            year:'',
            format:''
        }
    },
    mounted(){
        
    }
}
</script>
